import React from "react";
import { Link } from "gatsby";
import { Row, Col, Container } from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";
import { Helmet } from "react-helmet";

const Error = () => {
  return (
    <>
      <PageWrapper
        headerConfig={{
          align: "right",
          button: "account", // cta, account, null
        }}
      >
       <Helmet>
        <title>404 - Ethical Hacker Indonesia</title>  
        <meta name="description" content="Duh halaman yang kamu cari ga ada nih..." />
        <meta name="image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta property="og:url" content="https://hack.co.id/404/" />
        <meta property="og:title" content="404" />
        <meta property="og:description" content="Duh halaman yang kamu cari ga ada nih..." />
        <meta property="og:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@EthicalHackID" />
        <meta name="twitter:title" content="404"  />
        <meta name="twitter:description" content="Duh halaman yang kamu cari ga ada nih..." />
        <meta name="twitter:image" content="https://cdn.hack.co.id/images/light-thumb.jpg" />
      </Helmet>   
        <div className="inner-banner pt-29 pb-6">
          <Container>
            <Row className="justify-content-center pt-5">
              <Col xl="8" lg="9" md="11">
                <div className="px-xl-15 px-lg-8 text-center">
                  <i className="icon icon-fav-remove-2"></i>  
                  <h2 className="title gr-text-2 mb-9">
                    404
                  </h2>
                  <p className="gr-text-8 mb-13">
                    Duh halaman yang kamu cari ga ada nih...
                  </p>
                <div className="cta-btn text-lg">
                <Link to="/" className="btn btn-blue px-9 py-5 ml-lg-9">Kembali ke jalan yang benar</Link>
                </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        
      </PageWrapper>
    </>
  );
};
export default Error;
